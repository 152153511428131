<template>
  <div class="email-verification-container">
    <h1 class="email-verification-title">Email Verification Status</h1>
    <div class="email-verification-message">
      <p v-if="status === 'success'" class="success-message">
        ✅ Your email has been successfully verified!
      </p>
      <p v-else-if="status === 'already-verified'" class="info-message">
        📨 Your email is already verified.
      </p>
      <p v-else-if="status === 'invalid-link'" class="error-message">
        ❌ The verification link is invalid.
      </p>
      <p v-else class="loading-message">
        ⏳ Checking verification status...
      </p>
    </div>

    <!-- Add Login Button -->
    <div v-if="status === 'success'" class="login-button-container">
      <a class="twm-nav-sign-up bg-custom-color button-spacing" data-bs-toggle="modal" href="#sign_up_popup2" role="button">
        Login
      </a>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      status: this.$route.query.status || null,
    };
  },
};
</script>

<style scoped>
.email-verification-container {
  max-width: 600px;
  margin: 150px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background: #f9f9f9;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.email-verification-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

.email-verification-message {
  font-size: 1.2rem;
  line-height: 1.6;
}

.success-message {
  color: #28a745;
  font-weight: bold;
}

.info-message {
  color: #007bff;
  font-weight: bold;
}

.error-message {
  color: #dc3545;
  font-weight: bold;
}

.loading-message {
  color: #ffc107;
  font-style: italic;
}

.login-button-container {
  margin-top: 20px;
}

.login-button-container a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
}

.login-button-container a:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .email-verification-container {
    padding: 15px;
    font-size: 0.9rem;
  }
}
</style>
