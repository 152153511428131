<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 content">
                <h2>Roles</h2>
                <!-- Button to Trigger Modal -->
                <div class="d-flex mb-2">
                    <button type="button" class="btn bg-custom-color ms-auto" data-bs-toggle="modal"
                        data-bs-target="#addRole">
                        Add Role
                    </button>
                </div>

                <!-- Modal Start For Adding Role -->
                <div class="modal fade" id="addRole" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add Role</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    @click="resetForm"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="storeRole">
                                    <div>
                                        <label for="name" class="text-lg font-medium">Name</label>
                                        <div class="my-3">
                                            <input type="text" placeholder="Enter Name" v-model="roleName"
                                                class="form-control" />
                                        </div>
                                        <label for="description" class="text-lg font-medium">Description</label>
                                        <div class="my-3">
                                            <input type="text" placeholder="Enter Description" v-model="roleDescription"
                                                class="form-control" />
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <div class="d-flex flex-wrap" style="max-width: 100%; overflow: hidden">
                                                    <div class="form-check mt-3 me-3" v-for="permission in permissions"
                                                        :key="permission.permission_id"
                                                        style="flex: 0 0 calc(33.33% - 1rem)">
                                                        <input type="checkbox"
                                                            :id="'permission-' + permission.permission_id"
                                                            :value="permission.permission_id"
                                                            v-model="selectedPermissions" class="form-check-input" />
                                                        <label :for="'permission-' + permission.permission_id"
                                                            class="form-check-label">
                                                            {{ permission.permission_name }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <button type="submit" class="btn bg-custom-color">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal End For Adding Role -->

                <!-- Modal Start For Updating Role -->
                <div class="modal fade" id="updateRole" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Update Role</h5>
                                <button type="button" class="btn-close" aria-label="Close"
                                    @click="closeModal('updateRole')"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="updateRole">
                                    <div>
                                        <label for="name" class="text-lg font-medium">Name</label>
                                        <div class="my-3">
                                            <input type="text" placeholder="Enter Name" v-model="roleName"
                                                class="form-control" />
                                        </div>
                                        <label for="description" class="text-lg font-medium">Description</label>
                                        <div class="my-3">
                                            <input type="text" placeholder="Enter Description" v-model="roleDescription"
                                                class="form-control" />
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <div class="d-flex flex-wrap" style="max-width: 100%; overflow: hidden">
                                                    <div class="form-check mt-3 me-3" v-for="permission in permissions"
                                                        :key="permission.permission_id"
                                                        style="flex: 0 0 calc(33.33% - 1rem)">
                                                        <input type="checkbox"
                                                            :id="'permission-' + permission.permission_id"
                                                            :value="permission.permission_id"
                                                            v-model="selectedPermissions" class="form-check-input"
                                                            :checked="selectedPermissions.includes(permission.permission_id)" />
                                                        <label :for="'permission-' + permission.permission_id"
                                                            class="form-check-label">
                                                            {{ permission.permission_name }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                        <button type="submit" class="btn bg-custom-color">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal End For Updating Role -->

                <div>
                    <table class="table table-striped table-bordered text-center">
                        <thead class="bg-gray-50">
                            <tr>
                                <th class="px-6 py-3 text-left" width="60">#</th>
                                <th class="px-6 py-3 text-left">Name</th>
                                <th class="px-6 py-3 text-left">Description</th>
                                <th class="px-6 py-3 text-left">Permissions</th>
                                <th class="px-6 py-3 text-left" width="200">Created At</th>
                                <th class="px-6 py-3 text-center" width="240">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="roles.length === 0">
                                <td colspan="6" class="px-6 py-3 text-center">No roles found</td>
                            </tr>
                            <tr v-for="(role, index) in roles" :key="role.role_id" class="border-b">
                                <td class="px-6 py-3">{{ index + 1 }}</td>
                                <td class="px-6 py-3">{{ role.role_name }}</td>
                                <td class="px-6 py-3">{{ role.description }}</td>
                                <td class="px-6 py-3">
                                    <span v-if="role.permissions.length > 0">{{ role.permissions.join(", ") }}</span>
                                    <span v-else>No permissions assigned</span>
                                </td>
                                <td class="px-6 py-3">{{ formatDate(role.created_at) }}</td>
                                <td class="px-6 py-3">
                                    <button class="btn btn-primary button-spacing"
                                        @click="editRole(role.role_id)">Edit</button>
                                    <button class="btn btn-danger" @click="deleteRole(role.role_id)">Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import api from "../../api";

export default {
    data() {
        return {
            roles: [],
            permissions: [],
            roleName: "",
            roleDescription: "",
            selectedPermissions: [],
            currentRoleId: null,
        };
    },
    mounted() {
        this.fetchRoles();
        this.fetchPermissions();
    },
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        },
        async fetchRoles(url = "/roles") { 
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }
            try {
                const response = await api.get(url, { headers: { Authorization: `Bearer ${token}` } });
                this.roles = response.data.status ? response.data.roles : [];
            } catch (error) {
                console.error("Error fetching roles:", error);
                this.roles = [];
                this.handleAuthError(error);
            }
        },
        async fetchPermissions(url = "/roles/create") {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }
            try {
                const response = await api.get(url, { headers: { Authorization: `Bearer ${token}` } });
                this.permissions = response.data.status ? response.data.permissions : [];
            } catch (error) {
                console.error("Error fetching permissions:", error);
                this.permissions = [];
                this.handleAuthError(error);
            }
        },
        async storeRole() {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }
            try {
                const response = await api.post("/roles/store",
                    {
                        role_name: this.roleName,
                        description: this.roleDescription,
                        permissions: this.selectedPermissions,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token} `,
                        },
                    }
                );
                if (response.data.status) {
                    Swal.fire({ icon: "success", title: "Success", text: response.data.message });
                    this.fetchRoles();
                    this.resetForm();
                } else {
                    Swal.fire({ icon: "error", title: "Error", text: response.data.message });
                }
            } catch (error) {
                console.error("Error storing role:", error);
                Swal.fire({ icon: "error", title: "Error", text: "An error occurred while creating the role." });
            }
        },
        async editRole(role_id) {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }

            try {
                const response = await api.get(`/roles/edit/${role_id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.data && response.data.status) {
                    this.roleName = response.data.role.role_name;
                    this.roleDescription = response.data.role.description;

                    // Update the selectedPermissions to match permission_ids
                    this.selectedPermissions = response.data.assigned_permissions.map(permissionName => {
                        const permission = response.data.allPermissions.find(p => p.permission_name === permissionName);
                        return permission ? permission.permission_id : null;
                    }).filter(id => id !== null); 

                    this.currentRoleId = role_id;
                    this.showModal("updateRole");
                } else {
                    Swal.fire({ icon: "error", title: "Error", text: response.data.message || "An unexpected error occurred." });
                }
            } catch (error) {
                console.error("Error fetching role for edit:", error);
                Swal.fire({ icon: "error", title: "Error", text: "An error occurred while fetching the role." });
            }
        },
        async updateRole() {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }
            try {
                const response = await api.put(`/roles/update/${this.currentRoleId}`,
                    {
                        role_name: this.roleName,
                        description: this.roleDescription,
                        permissions: this.selectedPermissions,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (response.data.status) {
                    Swal.fire({ icon: "success", title: "Success", text: response.data.message });
                    this.fetchRoles();
                    this.closeModal("updateRole");
                } else {
                    Swal.fire({ icon: "error", title: "Error", text: response.data.message });
                }
            } catch (error) {
                console.error("Error updating role:", error);
                Swal.fire({ icon: "error", title: "Error", text: "An error occurred while updating the role." });
            }
        },
        async deleteRole(role_id) {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }
            const confirmDelete = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            });
            if (confirmDelete.isConfirmed) {
                try {
                    const response = await api.delete(`/roles/delete/${role_id}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if (response.data.status) {
                        Swal.fire({ icon: "success", title: "Deleted!", text: response.data.message });
                        this.fetchRoles();
                    } else {
                        Swal.fire({ icon: "error", title: "Error", text: response.data.message });
                    }
                } catch (error) {
                    console.error("Error deleting role:", error);
                    Swal.fire({ icon: "error", title: "Error", text: "An error occurred while deleting the role." });
                }
            }
        },
        resetForm() {
            this.roleName = "";
            this.roleDescription = "";
            this.selectedPermissions = [];
            this.currentRoleId = null;
        },
        showModal(modalId) {
            const modal = document.getElementById(modalId);
            if (modal) {
                modal.classList.add("show");
                modal.style.display = "block";
                document.body.classList.add("modal-open");

                const backdrop = document.createElement("div");
                backdrop.className = "modal-backdrop fade show";
                document.body.appendChild(backdrop);
            }
        },
        closeModal(modalId) {
            const modal = document.getElementById(modalId);
            if (modal) {
                modal.classList.remove("show");
                modal.style.display = "none";
                document.body.classList.remove("modal-open");

                const backdrop = document.querySelector(".modal-backdrop");
                if (backdrop) {
                    backdrop.remove();
                }
                this.resetForm(); 
            }
        },
        handleAuthError(error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "Unauthorized",
                    text: "Your session has expired. Please log in again.",
                });
            }
        },
    },
};
</script>


<style scoped>

</style>