<template>
    <div class="signup-form-container">
        <div class="form-content">
            <form>
                <div class="form-header">
                    <h2>Reset Your Password</h2>
                    <p>Changing password for: <span class="user-email">{{ email }}</span></p>
                </div>

                <div class="form-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group mb-3">
                                <input type="password" class="form-control" placeholder="New Password*"
                                    v-model="password" />
                                <span class="text-danger" v-if="errors.password">{{ errors.password }}</span>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group mb-3">
                                <input type="password" class="form-control" placeholder="Confirm Password*"
                                    v-model="password_confirmation" />
                                <span class="text-danger" v-if="errors.password_confirmation">{{
                                    errors.password_confirmation }}</span>
                            </div>
                        </div>

                        <!-- Hidden Token Field -->
                        <input type="hidden" v-model="token" />

                        <div class="col-md-12">
                            <button type="submit" class="site-button" @click.prevent="resetPassword">
                                Reset Password
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import api from '../../api';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            email: "",
            token: "",
            password: "",
            password_confirmation: "",
            errors: {},
        };
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        this.email = urlParams.get("email") || "";
        this.token = urlParams.get("token") || "";
    },
    methods: {
        async resetPassword() {
            this.errors = {};

            try {
                const response = await api.post("/reset-password", {
                    email: this.email,
                    token: this.token,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                });

                const result = response.data;

                if (result.status) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Password reset successfully.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                    });
                    this.password = '';
                    this.password_confirmation = '';
                    this.errors = {};
                } else if (result.errors) {
                    Object.keys(result.errors).forEach((key) => {
                        this.errors[key] = result.errors[key][0];
                    });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: result.message || 'Password reset failed.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                }
            } catch (error) {
                console.error("Error resetting password:", error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong. Please try again later.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            }
        }
        ,
    },
};
</script>

<style scoped>
.signup-form-container {
    max-width: 500px;
    margin: 150px auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-header {
    text-align: center;
}

.form-header h2 {
    font-size: 24px;
    font-weight: bold;
}

.user-email {
    font-weight: bold;
    color: #0077b5;
}

.form-body .form-group {
    margin-bottom: 15px;
}

.text-danger {
    color: red;
    font-size: 14px;
}

.site-button {
    width: 100%;
    padding: 10px;
    background-color: #0077b5;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
}

.site-button:hover {
    background-color: #005a94;
}
</style>
