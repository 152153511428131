<template>
  <nav class="navbar navbar-static-top">
    <div class="d-flex align-items-center">
      <div class="logo-container">
        <img src="../assets/images/logos/MW-white.png" class="logo h-100 w-100" alt="Logo" />
      </div>
      <IconField iconPosition="left">
        <InputIcon class="pi pi-search"></InputIcon>
        <InputText class="search_field" placeholder="Search Mentor" />
      </IconField>
    </div>
    <div class="h-100 options_container">
      <div class="d-flex align-items-center left-cont">
        <Button class="post_request_btn" label="Post Request" />
        <div class="icons-container">
          <img src="../assets/images/icons/message_icon.png" alt="Icon" class="icon" />
          <img src="../assets/images/icons/bell_icon.png" class="icon" alt="" />
        </div>
      </div>
      <div class="right-cont">
        <Avatar :label="userData.first_name.charAt(0) + userData.last_name.charAt(0)" class="mr-2" shape="circle" />
        <div class="user_info">
          <span>{{ userData.first_name }} {{ userData.last_name }}</span>
          <span>{{ userData.role_name }}</span>
        </div>
        <div class="dropdown-container">
          <InputIcon class="pi pi-bars" @click="toggleDropdown"></InputIcon>
          <div v-if="dropdownVisible" class="dropdown-menu">
            <a href="/profile" class="dropdown-item">Profile</a>
            <a href="/settings" class="dropdown-item">Settings</a>
            <a href="#" class="dropdown-item" @click.prevent="handleLogout">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Button from "primevue/button";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import Avatar from "primevue/avatar";
import axios from "axios";
import api from "../api";

export default {
  components: {
    Button,
    IconField,
    InputIcon,
    InputText,
    Avatar,
  },
  data() {
    return {
      dropdownVisible: false,
      userData: {
        first_name: '',
        last_name: '',
        role_name: '',
      },
    };
  },
  created() {
    this.fetchUserData();
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    async fetchUserData() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          window.location.href = "/";
          return;
        }

        const response = await api.get("/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.status) {
          this.userData = response.data.user_data;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        alert("An error occurred while fetching user data.");
      }
    },
    async handleLogout() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          window.location.href = "/";
          return;
        }
        const response = await api.get("/logout", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status) {
          localStorage.removeItem("token");
          window.location.href = "/";
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
        } else {
          alert("An error occurred while logging out. Please try again.");
          console.error(error);
        }
      }
    },
  },
};
</script>


<style scoped>
nav {
  height: 70px;
  background-color: var(--primary-blue);
  padding: 0 50px;
}

.logo-container {
  width: 149px;
  height: 30px;
  margin-right: 100px;
}

.search_field {
  border-radius: 19px;
  width: 443px;
  height: 38px;
  font-weight: 400;
  font-size: 12px;
}

.pi-search {
  color: black;
}

.post_request_btn {
  background: var(--green-color);
  font-weight: 500;
  font-size: 15px;
  border-radius: 19px;
  border: 1px solid var(--green-color);
  margin-right: 50px;
}

.left-cont {
  height: 100%;
  display: flex;
  border-right: 0.8px solid #ffffff9c;
  padding-right: 20px;
}

.right-cont {
  height: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  position: relative;
}

.options_container {
  display: flex;
  align-content: center;
}

.icons-container {
  display: flex;
  gap: 15px;
}

.icons-container .icon {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.user_info {
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-left: 15px;
}

.user_info span:first-child {
  font-size: 18px;
  font-weight: 500;
}

.user_info span:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
}

.pi-bars {
  color: #fff;
  font-size: 25px;
  margin-left: 60px;
  cursor: pointer;
}

.right-cont ::v-deep .p-avatar {
  height: 40px;
  width: 40px;
}

.dropdown-container {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 150px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  font-size: 14px;
}

.dropdown-item:hover {
  background-color: var(--primary-blue);
  color: #fff;
}
</style>
