<template>
  <Header />
  <!-- forms start -->
  <div class="modal fade twm-sign-up" id="sign_up_popup" aria-hidden="true" aria-labelledby="sign_up_popupLabel"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mt-0">
      <div class="modal-content">
        <form @submit.prevent="registerUser">
          <div class="modal-header">
            <h2 class="modal-title" id="sign_up_popupLabel">Sign Up</h2>
            <p>Sign Up and get access to all the features of Mentorwise</p>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body">
            <div class="twm-tabs-style-2">
              <div class="tab-content" id="myTabContent">
                <!--Signup Candidate Content-->
                <div class="tab-pane fade show active" id="sign-candidate">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <input id="first_name" type="text" class="form-control" v-model="registerformData.first_name"
                          :class="{ 'is-invalid': errors.first_name }" placeholder="First Name*" autofocus />
                        <span v-if="errors.first_name" class="text-danger">{{
                          errors.first_name[0]
                        }}</span>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <input id="last_name" type="text" class="form-control" v-model="registerformData.last_name"
                          :class="{ 'is-invalid': errors.last_name }" placeholder="Last Name*" autofocus />
                        <span v-if="errors.last_name" class="text-danger">{{
                          errors.last_name[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <input id="email" type="email" class="form-control" v-model="registerformData.email"
                          :class="{ 'is-invalid': errors.email }" placeholder="Email*" />
                        <span v-if="errors.email" class="text-danger">{{
                          errors.email[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <input id="password" type="password" class="form-control" v-model="registerformData.password"
                          :class="{ 'is-invalid': errors.password }" placeholder="Password*" />
                        <span v-if="errors.password" class="text-danger">{{
                          errors.password[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <input id="cpassword" type="password" class="form-control"
                          v-model="registerformData.password_confirmation" :class="{
                            'is-invalid': errors.password_confirmation,
                          }" placeholder="Confirm Password*" />
                        <span v-if="errors.password_confirmation" class="text-danger">{{ errors.password_confirmation[0]
                          }}</span>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <select class="form-select custom-drop" id="role_id" v-model="registerformData.role_id"
                          :class="{ 'is-invalid': errors.role_id }">
                          <option class="custom-option" value="">Select</option>
                          <option v-for="role in roles" :key="role.role_id" class="custom-option" :value="role.role_id">
                            {{ role.role_name }}
                          </option>
                        </select>
                        <span v-if="errors.role_id" class="text-danger">{{
                          errors.role_id[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="agree1"
                            v-model="registerformData.agree" />
                          <label class="form-check-label" for="agree1">
                            I agree to the
                            <a href="javascript:;">Terms and conditions</a>
                          </label>
                          <span v-if="errors.agree" class="text-danger">{{
                            errors.agree[0]
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <button type="submit" class="site-button" :disabled="loading">
                        Sign Up
                      </button>
                    </div>
                    <div v-if="successMessage" class="alert alert-success mt-3">
                      {{ successMessage }}
                    </div>
                    <div class="col-lg-12 text-center">
                      <div class="form-group mb-3">
                        <p>
                          Already registered?
                          <button class="twm-backto-login" data-bs-target="#sign_up_popup2" data-bs-toggle="modal"
                            data-bs-dismiss="modal">
                            Log in here
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <span class="modal-f-title">Login or Sign up with</span>
            <ul class="twm-modal-social">
              <li>
                <a href="javascript" class="facebook-clr">
                  <i class="fab fa-facebook-f p-t8"></i>
                </a>
              </li>
              <li>
                <a href="javascript" class="twitter-clr">
                  <i class="fab fa-twitter p-t8"></i>
                </a>
              </li>
              <li>
                <a href="javascript" class="linkedin-clr">
                  <i class="fab fa-linkedin-in p-t8"></i>
                </a>
              </li>
              <li>
                <a href="javascript" class="google-clr">
                  <i class="fab fa-google p-t8"></i>
                </a>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Login Popup Section Start -->
  <div>
    <div class="modal fade twm-sign-up" id="sign_up_popup2" aria-hidden="true" aria-labelledby="sign_up_popupLabel2"
      tabindex="-1">
      <div class="modal-dialog modal-dialog-centered mt-0">
        <div class="modal-content">
          <form @submit.prevent="loginUser" class="m-b0">
            <div class="modal-header">
              <h2 class="modal-title" id="sign_up_popupLabel2">Login</h2>
              <p>Login and get access to all the features of MentorWise</p>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <div v-if="errorsMessage" class="alert alert-danger mt-3">
                {{ errorsMessage }}
              </div>
            </div>
            <div class="modal-body">
              <div class="twm-tabs-style-2">
                <div class="tab-content" id="myTab2Content">
                  <div class="tab-pane fade show active" id="login-candidate">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-3">
                          <input v-model="loginFormData.email" id="email" type="email" class="form-control required"
                            autocomplete="email" autofocus placeholder="Email" />
                          <span v-if="loginErrors.email" class="text-danger">{{ loginErrors.email[0] }}</span>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="form-group mb-3">
                          <input v-model="loginFormData.password" id="password" type="password" class="form-control"
                            autocomplete="current-password" placeholder="Password" />
                          <span v-if="loginErrors.password" class="text-danger">{{ loginErrors.password[0] }}</span>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="form-group mb-3">
                          <div class="form-check">
                            <input v-model="loginFormData.remember" class="form-check-input" type="checkbox"
                              id="remember" />
                            <label class="form-check-label rem-forgot" for="remember">
                              Remember me
                              <button type="button" class="twm-backto-login" data-bs-target="#forget_password_popup"
                                data-bs-toggle="modal" data-bs-dismiss="modal">
                                Forgot Password?
                              </button>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <button type="submit" class="site-button">
                          Log in
                        </button>
                        <div class="mt-3 mb-3 text-center">
                          Don't have an account?
                          <button class="twm-backto-login" data-bs-target="#sign_up_popup" data-bs-toggle="modal"
                            data-bs-dismiss="modal">
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <span class="modal-f-title">Login or Sign up with</span>
              <ul class="twm-modal-social">
                <li>
                  <a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f p-t8"></i></a>
                </li>
                <li>
                  <a href="javascript" class="twitter-clr"><i class="fab fa-twitter p-t8"></i></a>
                </li>
                <li>
                  <a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in p-t8"></i></a>
                </li>
                <li>
                  <a href="javascript" class="google-clr"><i class="fab fa-google p-t8"></i></a>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Login Popup Section End -->

  <!-- Forget-Password Popup Section Start -->
  <div class="modal fade twm-sign-up" id="forget_password_popup" aria-hidden="true" aria-labelledby="sign_up_popupLabel"
    tabindex="-1" @hide.bs.modal="closeModals">
    <div class="modal-dialog modal-dialog-centered mt-0">
      <div class="modal-content">
        <form @submit.prevent="forgetPassword" class="m-b0" action="">
          <div class="modal-header">
            <h2 class="modal-title" id="forget_password_popupLabel">
              Forget your password?
            </h2>
            <p>Enter your registered email to send the invitation link</p>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="twm-tabs-style-2">
              <div class="tab-content" id="myTab2Content">
                <div class="tab-pane fade show active" id="forget_password">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <input id="email" type="email" class="form-control" name="email" value="" required
                          autocomplete="email" autofocus placeholder="Email*" v-model="forgetPasswordData.email" />
                        <!-- Display backend error for email -->
                        <span v-if="fogotPasswordErrors.email" class="text-danger">{{ fogotPasswordErrors.email[0]
                          }}</span>
                      </div>
                      <span class="invalid-feedback" role="alert">
                        <strong></strong>
                      </span>
                    </div>
                    <div class="col-md-12">
                      <button type="submit" class="site-button" :disabled="loading">
                        Reset Password
                      </button>
                      <div class="mt-3 mb-3">
                        Don't have an account?
                        <button class="twm-backto-login" data-bs-target="#sign_up_popup" data-bs-toggle="modal"
                          data-bs-dismiss="modal">
                          Sign Up
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="successMessage" class="alert alert-success mt-3">
              {{ successMessage }}
            </div>
          </div>



          <div class="modal-footer">
            <span class="modal-f-title">Login or Sign up with</span>
            <ul class="twm-modal-social">
              <li><a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f p-t8"></i></a></li>
              <li><a href="javascript" class="twitter-clr"><i class="fab fa-twitter p-t8"></i></a></li>
              <li><a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in p-t8"></i></a></li>
              <li><a href="javascript" class="google-clr"><i class="fab fa-google p-t8"></i></a></li>
            </ul>
          </div>

        </form>
      </div>
    </div>
  </div>
  <!-- Forget-Password Popup Section End -->


  <!-- forms end-->

  <div class="middle-content">
    <router-view />
  </div>
  <!-- BUTTON TOP START -->
  <button class="scroltop">
    <span class="fa fa-angle-up relative" id="btn-vibrate"></span>
  </button>

  <Footer />
</template>

<script>
import Header from "../components/website/Header.vue";
import Footer from "../components/website/Footer.vue";
import "@/assets/template/css/style.css";
import "@/assets/template/css/mystyle.css";

import "@/assets/template/js/jquery-3.6.0.min.js";
import "@/assets/template/js/popper.min.js";
import "@/assets/template/css/bootstrap.min.css";
import "@/assets/template/css/font-awesome.min.css";
import "@/assets/template/css/feather.css";
import "@/assets/template/css/owl.carousel.min.css";
import "@/assets/template/js/owl.carousel.min.js";
import "@/assets/template/css/magnific-popup.min.css";
import "@/assets/template/css/bootstrap-select.min.css";
import "@/assets/template/css/dataTables.bootstrap5.min.css";
import "@/assets/template/css/select.bootstrap5.min.css";
import "@/assets/template/css/dropzone.css";
import "@/assets/template/css/scrollbar.css";
import "@/assets/template/css/datepicker.css";
import "@/assets/template/js/bootstrap.min.js";
import "@/assets/template/js/magnific-popup.min.js";
import "@/assets/template/js/waypoints.min.js";
import "@/assets/template/js/waypoints-sticky.min.js";
import "@/assets/template/js/counterup.min.js";
import "@/assets/template/js/isotope.pkgd.min.js";
import "@/assets/template/js/imagesloaded.pkgd.min.js";
import "@/assets/template/js/theia-sticky-sidebar.js";
import "@/assets/template/js/lc_lightbox.lite.js";
import "@/assets/template/js/dropzone.js";
import "@/assets/template/js/jquery.scrollbar.js";
import "@/assets/template/js/chart.js";
import "@/assets/template/js/custom.js";
import api from "../api";
import axios from "axios";

export default {
  components: { Header, Footer },
  data() {
    return {
      registerformData: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role_id: "",
        agree: false,
      },
      loginFormData: {
        email: "",
        password: "",
        remember: false,
      },
      forgetPasswordData: {
        email: "",
      },
      roles: [],
      errors: {},
      loginErrors: {},
      fogotPasswordErrors: {},
      successMessage: "",
      errorsMessage: "",
      loading: false,
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    closeModals() {
      const signUpModal = new bootstrap.Modal(document.getElementById('sign_up_popup'));
      signUpModal.hide();
    },
    async fetchRoles() {
      try {
        const response = await api.get("/allRoles");
        if (response.data.status) {
          this.roles = response.data.data;
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    },
    async registerUser() {
      this.loading = true;
      this.errors = {};
      try {
        const response = await api.post("/userRegister", this.registerformData);
        this.successMessage = response.data.message;
        this.clearForm();
        this.hideSuccessMessage();
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          this.errors = error.response.data.errors;
        } else {
          console.error("An error occurred:", error);
        }
      } finally {
        this.loading = false;
      }
    },
    async loginUser() {
      this.loading = true;
      this.loginErrors = {};
      this.errorsMessage = ""; 
      try {
        const response = await api.post("/login", this.loginFormData);
        this.successMessage = response.data.message; 
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.user.role);
        this.$router.push(`/dashboard/${response.data.user.role}`); 
        this.clearLoginForm();
        this.hideSuccessMessage();
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.data.status === false) {
            this.errorsMessage = error.response.data.message;
          } else if (error.response.data.errors) {
            this.loginErrors = error.response.data.errors;
          } else {
            this.errorsMessage = "An unexpected error occurred. Please try again.";
            console.error("An error occurred:", error);
          }
        } else {
          console.error("An error occurred:", error);
          this.errorsMessage = "Unable to connect to the server.";
        }
      } finally {
        this.loading = false;
      }
    },
    async forgetPassword() {
      this.loading = true;
      this.fogotPasswordErrors = {};
      this.successMessage = "";
      try {
        const response = await api.post("/forgot-password", this.forgetPasswordData);
        this.successMessage = response.data.message;
        this.clearForgetPasswordForm();
        this.hideSuccessMessage();
      } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          this.fogotPasswordErrors = error.response.data.errors;
        } else if (error.response && error.response.data) {
          this.errorsMessage = error.response.data.message || "An error occurred.";
        } else {
          console.error("An error occurred:", error);
        }
      } finally {
        this.loading = false;
      }
    },
    clearForgetPasswordForm() {
      this.forgetPasswordData = {
        email: "",
      };
      this.fogotPasswordErrors = {};
    },
    clearForm() {
      this.registerformData = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role_id: "",
        agree: false,
      };
      this.errors = {};
    },
    clearLoginForm() {
      this.loginFormData = {
        email: "",
        password: "",
        remember: false,
      };
      this.loginErrors = {};
      this.errorsMessage = "";
    },
    hideSuccessMessage() {
      setTimeout(() => {
        this.successMessage = "";
      }, 3000);
    },
  },
};

</script>

<style scoped>
/* @import "@/assets/template/css/style.css";
@import "@/assets/template/css/mystyle.css"; */
</style>