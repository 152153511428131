import { createRouter, createWebHistory } from "vue-router";
import FullLayout from "../views/FullLayout.vue";
import Homepage from "../components/website/Homepage.vue";
import AboutUs from "../components/website/AboutUs.vue";
import Mentors from "../components/website/Mentors.vue";
// import AboutUs from "../components/website/Mentors.vue";
import Contact from "../components/website/Contact.vue";
import WebLayout from "../views/WebLayout.vue";
import Dashboard from "../components/Dashboard.vue";
import MyRequest from "../components/MyRequest.vue";
import MySchedule from "../components/MySchedule.vue";
import SessionHistory from "../components/SessionHistory.vue";
import FeedbackAndRatings from "../components/FeedbackAndRatings.vue";
import AccountAndSettings from "../components/AccountAndSettings.vue";
import AccountSettings from "../components/AccountSettings.vue";
import Notifications from "../components/Notifications.vue";
import Plan from "../components/Plan.vue";
import Billing from "../components/Billing.vue";
import AdminDashboard from "../components/admin/AdminDashboard.vue";
import MentorDashboard from "../components/mentor/MentorDashboard.vue";
import SuperAdminDashboard from "../components/super_admin/SuperAdminDashboard.vue";
import MenteeDashboard from "../components/mentee/MenteeDashboard.vue";
import StudentDashboard from "../components/student/StudentDashboard.vue";
import AddPermission from "../components/permissions/AddPermission.vue";
import AddRole from "../components/roles/AddRole.vue";
import AddUser from "../components/users/AddUser.vue";
import MentorPage from "../components/admin/mentors/MentorPage.vue";
import EmailVerification from "../components/auth/EmailVerification.vue";
import ResetPassword from "../components/auth/ResetPassword.vue";
const routes = [
  {
    path: "/dashboard",
    component: FullLayout,
    children: [
      {
        path: "",
        component: Dashboard,
      },
      {
        path: "mentor",
        component: MentorDashboard,
        meta: { requiresAuth: true, role: "mentor" },
      },
      {
        path: "mentee",
        component: MenteeDashboard,
        meta: { requiresAuth: true, role: "mentee" },
      },
      {
        path: "admin",
        component: AdminDashboard,
        meta: { requiresAuth: true, role: "admin" },
      },
      {
        path: "super_admin",
        component: SuperAdminDashboard,
        meta: { requiresAuth: true, role: "super_admin" },
      },
      {
        path: "student",
        component: StudentDashboard,
        meta: { requiresAuth: true, role: "student" },
      },
      {
        path: "/my-request",
        component: MyRequest,
      },
      {
        path: "/my-schedule",
        component: MySchedule,
      },
      {
        path: "/session-history",
        component: SessionHistory,
      },
      {
        path: "/feedback-&-ratings",
        component: FeedbackAndRatings,
      },
      {
        path: "/permissions",
        component: AddPermission,
      },
      {
        path: "/roles",
        component: AddRole,
      },
      {
        path: "/users",
        component: AddUser,
      },
      {
        path: "/mentor-page",
        component: MentorPage,
      },
      {
        path: "/account-&-settings",
        component: AccountAndSettings,
        children: [
          // {
          //   path: "",
          //   component: AccountSettings,
          // },
          {
            path: "/account-settings",
            component: AccountSettings,
          },
          {
            path: "/notifications",
            component: Notifications,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: WebLayout,
    children: [
      {
        path: "",
        component: Homepage,
      },
      {
        path: "/about",
        component: AboutUs,
      },
      {
        path: "/mentors",
        component: Mentors,
      },
      {
        path: "/contact",
        component: Contact,
      },
      {
        path: '/email-verification',
        name: 'EmailVerification',
        component: EmailVerification,
      },
      {
        path: '/reset-password',
        component: ResetPassword,
        name: 'ResetPassword',
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");
  const userRole = localStorage.getItem("role");

  if (to.path.startsWith("/dashboard") && !isAuthenticated) {
    next({ path: "/" });
  } else if (to.meta.requiresAuth && !isAuthenticated) {
    next({ path: "/" });
  } else if (to.meta.role && to.meta.role !== userRole) {
    next({ path: `/dashboard/${userRole}` });
  } else {
    next();
  }
});

export default router;

// {
//   path: "/about",
//   name: "about",
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () =>
//     import(/* webpackChunkName: "about" */ "../components/AboutView.vue"),
// lazyloadingsyntax},
